import React, { Component, Fragment } from "react";
export default class OnBoardingHeadingInfo extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const fields = this.props.node.data.target.fields;
		return (
			<div className="fantasy padding-100" style={this.props.style}>
				<h2 class="heading-2">{fields.heading}</h2>
				<div class="paragraph">
					<div class="" style={{ height: "auto" }}>
						<p style={{ textAlign: "left" }}>{fields.description}</p>
					</div>
					<a class="read-more team-know-more" href={fields.link && fields.link.link}>
						{fields.link && fields.link.linkText}
					</a>
				</div>
			</div>
		);
	}
}
