
const App =() =>{

}

const moveToWelcome=()=>{
    const menuData = JSON.parse(sessionStorage.getItem("menuData"));
    if(menuData )
        return  `${menuData[0].path}/${menuData[0].id}`;
    
    return '';	
}

export {moveToWelcome};