import client from "../../configuration/Client";
import ContentFooterImg from "../common/ContentFooterImg";
import ContentHeaderImg from "../common/ContentHeaderImg";
import React, { Component, Fragment } from "react";
import Footer from "../footerHeader/Footer";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import "../../css/MyPorsche.scss";
import OnBoardingHeadingInfo from "../contentType/onboardingHeadingInfo/OnBoardingHeadingInfo";
import OnBoardingTable from "../contentType/onboardingTable/OnBoardingTable";
import OnBoardingPerson from "../contentType/onboardingperson/OnBoardingPerson";
import Heading3 from "../dom/Heading3";
import OnBoardingImageBlock from "../contentType/onboardingImageBlock/OnBoardingImageBlock";
import { commonRenderNode } from "../../constant/appConstant";
import HrAcccordion from "../common/hraccordion/HrAcccordion";

export default class YourDocumentsMenu extends React.Component {
	constructor(props) {
		super(props);
		const {
			match: { params }
		} = this.props;
		this.state = {
			menuItem: "",
			pageContent: "",
			pageFooter: {},
			pageHeader: JSON.parse(sessionStorage.getItem(params["id"])),
			id: params["id"],
			main: ""
		};
	}

	componentDidMount() {
		var aScript = document.createElement('script');
		aScript.type = 'text/javascript';
		aScript.src = "https://porsche.sslsurvey.de/lp/js/lp.ext.js";
	
		document.head.appendChild(aScript);
		aScript.onload = function() {
			window.lp_load_survey('lp_survey_195468', 'https://porsche.sslsurvey.de/WelcomeGuide/', 'auto');
		};
		//console.log(window)

		let options = {
			renderNode: {
				...commonRenderNode,
				["heading-3"]: node => {
					return (
						<div class="headerParagraph feedbackBackground header-feed">
							<Heading3 node={node} />
						</div>
					);
				},
				["paragraph"]: node => {
					if (node.content && node.content.length == 1) {
						return (
							<div class="headerParagraph feedbackBackground header-para paragraph card-1">
								<p style={{ textAlign: "left" }}>
									{node.content && node.content[0].value}
								</p>
							</div>
						);
					} else if (node.content && node.content.length > 1) {
						if (node.content[1].nodeType === "embedded-entry-inline" && node.content[1].data.target.sys.contentType.sys.id == "onboardingPerson") {
							return (
								<React.Fragment>
									<OnBoardingPerson isEntryBlock={false} pageContent={node} />
								</React.Fragment>
							);
						} else if (node.content[1].nodeType === "embedded-entry-inline" && node.content[1].data.target.sys.contentType.sys.id === "onboardingAccordion") {

							return (

								<div className="btm-acr-container">
									<div className="section-container" >
										<HrAcccordion pageContent={node} />

									</div>
								</div>


							)
						}
					}
				},
				["embedded-entry-block"]: (node) => {
					if (node.data.target.sys.contentType.sys.id == "onboardingTable") {
						return <OnBoardingTable node={node} />;
					} else if (node.data.target.sys.contentType.sys.id == "onboardingHeadingInfo") {
						return <OnBoardingHeadingInfo node={node} style={{ padding: "0px 0px" }} />;
					} else if (node.data.target.sys.contentType.sys.id == "onboardingImageBlock") {
						return (
							<div className="">
								<OnBoardingImageBlock style={{ marginTop: "7%" }} node={node} className="row" innerChildClass="col-sm-9 pad-lg-rt-0" />
							</div>
						);
					}
				}
			}
		};
		client
			.getEntries({
				content_type: "onboardingPage",
				"sys.id": this.state.id,
				include: 10
			})
			.then(entry => {
				const { fields } = entry.items[0];
				if (fields) {
					this.setState({
						menuItem: fields.menuItem,
						//pageHeader: fields.pageHeader,
						pageFooter: fields.pageFooter,
						main: fields.pageContent,
						pageContent: documentToReactComponents(fields.pageContent, options)
					});
				}
			});
		window.scrollTo(0, 0);
	}
	render() {

		return (
			<div>
			<main className="content-area">
				<main>
					<ContentHeaderImg pageHeader={this.state.pageHeader} />
					<section className="section-container doc-6-container product-container">
						<div className="content-detail">
							<div className="welcome-content-detail docs-6">

								<div className="section-container feedbackPage pageContents pageItems">{this.state.pageContent}
									{/* {<iframe className="feedback-frame" src="https://survey.lamapoll.de/Feedback_11/?ref=iframe&mode=fixed" scrolling="no" frameborder="0"></iframe>} */}
									<div className="iframe-container" id="lp_survey_195468"></div>
								</div>

							</div>
						</div>
					</section>
					
				</main>
			</main>
			{ this.state.pageContent ? (<Footer pageFooter={this.state.pageFooter} />):('')}
			</div>
		);
	}
}
