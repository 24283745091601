import React, { Component } from "react";
import "../../css/MainContent.scss";
import "../../css/MainContent-mediaQuerry.scss";
import { Dropdown } from "react-bootstrap";
import { appConstant } from "../../constant/appConstant";
import { withRouter } from "react-router-dom";
import Footer from "../footerHeader/Footer";

class SelectedTeam extends React.Component {
	constructor(props) {
		super(props);
		sessionStorage.clear("selectedTeam");
		this.state = {
			pageFooter: {}
		};
	}

	selectTeamHandler(selectedTeam) {
		sessionStorage.setItem("selectedTeam", selectedTeam);
		this.props.history.push("welcome/42QIShwwjKbv3BNZO4Tjnl");
		this.props.teamSelectHandler();
	}

	render() {
		return (
			<main className="content-area main-content-area">
				<main>
					<div className="content-detail">
						<div className="welcome-content-detail">
							<div className="pageContents">
								<div className="section-container">
									<div style={{ height: "auto", marginTop: "500px !important" }}>
										<div style={{ textAlign: "center", color: "black" }}>
											<br />
											<h3>Select Your My Porsche Team</h3>
											<br />
											<Dropdown alignRight title="Dropdown right" id="dropdown-menu-align-right" onSelect={e => this.selectTeamHandler(e)}>
												<Dropdown.Toggle variant="success" id="dropdown-basic">
													Select Your Team
												</Dropdown.Toggle>
												<Dropdown.Menu>
													{this.props.allTeams.map(teams => {
														return <Dropdown.Item eventKey={teams}>{teams}</Dropdown.Item>;
													})}
												</Dropdown.Menu>
											</Dropdown>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
				<Footer teamSelectionPage={true} pageFooter={this.props.pageFooter} />
			</main>
		);
	}
}

export default withRouter(SelectedTeam);
