import { Carousel } from "react-responsive-carousel";
import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ImageTag } from "../../dom/ImageTag";

export default class OnBoardingSlider extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { node } = this.props;
		return (
			<div>
				<Carousel autoPlay infiniteLoop="true" autoPlay={false} width={1000} showThumbs={false}>
					{node.data.target.fields.slides.map(function(item, key) {
						if (item.fields.linkText) {
							var arrowRight = <i className="icon-arrow-right"></i>;
						}
						return (
							<div className="support-bg-img" style={{ objectFit: "contain", backgroundSize: "cover", height: "100%" }}>
								<ImageTag className="img-fluid" src={item.fields.image.fields.file.url} isLazyLoad={false} />
								<div className="support-content-area text-left">
									<h1 className="heading-2">{item.fields.headline}</h1>
									<Router>
										<a className="img-linktext" href={item.fields.link}>
											{arrowRight}
											<span> {item.fields.linkText}</span>
										</a>
										<Route path={item.fields.link} />
									</Router>
								</div>
							</div>
						);
					})}
				</Carousel>
			</div>
		);
	}
}
