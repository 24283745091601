import client from "../../configuration/Client";
import ContentFooterImg from "../common/ContentFooterImg";
import ContentHeaderImg from "../common/ContentHeaderImg";
import React, { Component, Fragment } from "react";
import Footer from "../footerHeader/Footer";
import "../../css/HowWeWork.scss";
import Accordion from "../common/accordion";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { commonRenderNode } from "../../constant/appConstant";
import Heading3 from "../dom/Heading3";
import ImgeBarBlock from "../contentType/onboardingImageBar/ImgeBarBlock";


export default class HowWeWrokMenu extends React.Component {
	constructor(props) {
		super(props);
		const {
			match: { params }
		} = this.props;
		this.state = {
			menuItem: "",
			pageContent: "",
			pageFooter: {},
			pageHeader: JSON.parse(sessionStorage.getItem(params["id"])),
			id: params["id"]
		};
	}
	componentDidUpdate() {}
	componentDidMount() {
		let options = {
			renderNode: {
				...commonRenderNode,
				["heading-3"]: node => {
					return (
						<div class="headerParagraph headingmainpage">
							<Heading3 node={node} />
						</div>
					);
				},
				["paragraph"]: node => {
					if (node.content && node.content.length == 1) {
						return (
							<div className="card-1" style={{ height: "auto" }}>
								<p class="praghaph-3" style={{ textAlign: "left" }}>
									{node.content && node.content[0].value}
								</p>
							</div>
						);
					} else if (node.content && node.content.length > 1) {
						if (
							node.content[1].nodeType === "embedded-entry-inline" &&
							node.content[1].data.target.sys.contentType.sys.id === "onboardingTeaser"
						) {
							
							return (
								<div className="section-container card-section-container card-container">
									<ImgeBarBlock pageContent={node} />
								</div>
							);
						} else {
							return "";
						}
					}
				},
				["embedded-entry-block"]: (node)=> {

					
				}
			}
		};

		client
			.getEntries({
				content_type: "onboardingPage",
				"sys.id": this.state.id,
				include: 10
			})
			.then(entry => {
				const { fields } = entry.items[0];
				if (fields) {
					this.setState({
						menuItem: fields.menuItem,
						//pageHeader: fields.pageHeader,
						pageFooter: fields.pageFooter,
						pageContent:  documentToReactComponents(fields.pageContent, options)
					});
				}
			});
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<div>
			<main className="content-area">
				<main>
					<ContentHeaderImg pageHeader={this.state.pageHeader} />
					{/* <section className="section-container sec-wrap-margin-tp"> */}
						<div className="content-detail">
							<div className="welcome-content-detail howwework-page">
								<div className="pageContents displayItems">
									{this.state.pageContent}
								</div>
							</div>
						</div>
					{/* </section> */}

					<ContentFooterImg pageFooter={this.state.pageFooter} pathName={this.props.history.location.pathname} />
				
				</main>
			</main>
			{ this.state.pageContent ? (<Footer pageFooter={this.state.pageFooter} />):('')}
							</div>
		);
	}
}
