import React, { Component } from "react";
import "../../css/header.scss";
import logo from "../../images/porsche-logo.jpeg";
import { appConstant } from "../../constant/appConstant";
import { withRouter, Redirect } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Thumbs } from "react-responsive-carousel";
import client from "../../configuration/Client";
import { ImageTag } from "../dom/ImageTag";
import { moveToWelcome } from "../common/func";

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			chapter: "",
			menuItem: []
		};
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
	}
	routingPaths = [];
	routingIndex = 0;
	previous() {
		let menuNameArr = [];
		this.props.menuData.forEach(element => {
			menuNameArr.push(element.name);
		});
		let menuIndexArr = [];
		this.props.menuData.forEach(element => {
			menuIndexArr.push(element.path);
		});
		if (this.props.history) {
			let pathName = window.location.pathname;
			pathName = pathName.substring(0, pathName.lastIndexOf("/"));
			let index = menuIndexArr.indexOf(pathName);
			this.routingIndex = index - 1;
			this.props.history.push(menuIndexArr[this.routingIndex] + "/" + this.props.menuData[this.routingIndex].id);
		}
	}
	next() {
		let menuNameArr = [];
		this.props.menuData.forEach(element => {
			menuNameArr.push(element.name);
		});
		let menuIndexArr = [];
		this.props.menuData.forEach(element => {
			menuIndexArr.push(element.path);
		});
		if (this.props.history) {
			let pathName = window.location.pathname;
			pathName = pathName.substring(0, pathName.lastIndexOf("/"));
			let index = menuIndexArr.indexOf(pathName);
			this.routingIndex = index + 1;
			let uri = menuIndexArr[this.routingIndex] + "/" + this.props.menuData[this.routingIndex].id;
			this.props.history.push(menuIndexArr[this.routingIndex] + "/" + this.props.menuData[this.routingIndex].id);
		}
	}
	componentDidMount() {}

	changeTeamHandler = () => {
		this.props.changeTeamHandler();
		window.location.href = "/";
	};



	render() {
		let path=moveToWelcome();
		let logOut=''
		if (this.props.menuData && this.props.menuData.length > 0 ) {
			logOut=	<span className="l" onClick={this.changeTeamHandler}>Logout </span>
		}
			return (
				<React.Fragment>
					<header className="w-100 mt-0 row h-8 m-0 p-0 header ">
						<div class="" id="">
							<div class="header-left header-text-pd-tp">
								<span > Welcome Guide</span>
							</div>
						</div>
						<div className="col-lg-4 col-md-3 col-sm-3 col-3"></div>
						<div className="col-lg-4 col-md-6 col-sm-6 col-6 logo-box text-center">
						<a className="" href={path}>
							<ImageTag className="logo" src={logo} isLazyLoad={false} />
							</a>
						</div>
						
					</header>
				</React.Fragment>
			);
		
	}
}
export default withRouter(Header);
