import React from 'react'
import './OnboardingNumberHeading.scss'

function OnboardingNumberHeading({node}) {

    return (
        <div className="row verschiedene">
            {
                node && node.filter(e=> e.nodeType === "embedded-entry-inline" ).map((item,key)=>{
                    console.log(item);
                  return ( <div className="col-md-4 col-12 text-center">
                            <div className="row justify-content-center heading">
                                <span>{item.data.target.fields.numberHeading}</span>
                            </div>
                            <div className="row justify-content-center">
                                
                            <span>{item.data.target.fields.numberText}</span>

                            </div>

                  </div>)
                }
                )
            }
        </div>

    )
}

export default OnboardingNumberHeading
