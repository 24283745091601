import React, { Component, Fragment } from "react";
import { ImageTag } from "../../dom/ImageTag";
export default class OnBoardingTeaser extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const fields = this.props.node.data.target.fields;
		return (
			<div>
				<div>
					<div className="support-bg-img porsche-principle-video">
						<div class="d-flex event-sec">
							<div class="d-flex justify-content-center event-img">
								<ImageTag className="img-fluid" src={fields.image && fields.image.fields.file.url} isLazyLoad={false} />
							</div>
							<div class="d-flex justify-content-right" style={{ flex: "3 1 0%" }}>
								<div class="event-detail">
									<p style={{ color: "#000000" }} className="headline ">{fields.headline}</p>
									<p className="praghaph">{fields.subline}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
