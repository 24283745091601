import BlackQuote from "../components/common/BlackQuote"
import Heading2 from "../components/dom/Heading2";
import Heading3 from "../components/dom/Heading3";
import Heading4 from "../components/dom/Heading4";
import React from "react";
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import Heading5 from "../components/dom/Heading5";
import Heading6 from "../components/dom/Heading6";
import HrTag from "../components/dom/HrTag";

const appConstant = {
  MY_PORSCHE: 'My Porsche',
  WELCOME_GUIDE: 'Welcome Guide',
  BACK: 'Back',
  NEXT: 'Next',
  LOGOUT: 'Logout',
  ROUTE_MAP:
  {
    welcome: "welcome",
    principal: 'principal',
    porsche: 'myporsche',
    work: 'howwewrokmenu',
    documents: 'yourdocumentsmenu',
    support: 'supportmenu'
  },
  TEAMS:
  {
    MY_PORSCHE_AFTERSALES: 'MyPorsche AfterSales',
    PORSCHE_ID_PROFILE: 'Porsche Id Profile',
    PORSCHE_ID_TEAMS: 'Porsche Id Teams'
  }
};


const commonRenderNode = {
  [BLOCKS.HEADING_6]: node => {
    return <Heading6 node={node} />;
  },
  [BLOCKS.HEADING_5]: node => {
    return <Heading5 node={node} />;
  },
  [BLOCKS.HEADING_4]: node => {
    return <Heading4 node={node} />;
  },
  [BLOCKS.QUOTE]: node => {
    return <BlackQuote node={node} class="section-container" style={{ height: "auto", margin: "0px 0px 0px 0px" }} />;
  },
  [BLOCKS.HEADING_3]: node => {
    return <Heading3 node={node} />;
  },
  [BLOCKS.HEADING_2]: node => {
    return <Heading2 node={node} />;
  },
  [BLOCKS.HR]: node => {
    return <HrTag node={node} />;
  }

}

const REGEX_MENU_SERIAL_NO=/\d+.+[0-9]/g
const REGEX_MENU_GERMEN_TITLE=/[ a-zA-ZÄäÖöÜü&-]+/g

export { appConstant, commonRenderNode,REGEX_MENU_SERIAL_NO,REGEX_MENU_GERMEN_TITLE }