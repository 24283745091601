import client from "../../configuration/Client";
import ContentHeaderImg from "../common/ContentHeaderImg";
import React, { Component, Fragment } from "react";
import Footer from "../footerHeader/Footer";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import "../../css/MyPorsche.scss";
import OnBoardingTeaser from "../contentType/onboardingTeaser/OnBoardingTeaser";
import { commonRenderNode } from "../../constant/appConstant";

export default class SupportMenu extends React.Component {
	constructor(props) {
		super(props);
		const {
			match: { params }
		} = this.props;
		this.state = {
			menuItem: "",
			pageContent: "",
			pageFooter: {},
			pageHeader: {},
			id: params["id"]
		};
	}
	componentDidMount() {
		let options = {
			renderNode: {
				...commonRenderNode,
				["embedded-entry-block"]: node => {
					return <OnBoardingTeaser node={node} />;
				}
			}
		};
		client
			.getEntries({
				content_type: "onboardingPage",
				"sys.id": this.state.id,
				include: 10
			})
			.then(entry => {
				const { fields } = entry.items[0];
				if (fields) {
					this.setState({
						menuItem: fields.menuItem,
						pageHeader: fields.pageHeader,
						pageFooter: fields.pageFooter,
						pageContent: documentToReactComponents(fields.pageContent, options)
					});
				}
			});
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<main className="content-area">
				<main>
					<ContentHeaderImg pageHeader={this.state.pageHeader} />
					<section className="section-container sec-wrap-margin-tp">
						<div className="content-detail">
							<div>
								<div className="pageContents">{this.state.pageContent}</div>
							</div>
						</div>
					</section>
					<Footer pageFooter={this.state.pageFooter} />
				</main>
			</main>
		);
	}
}
