import React, { Component, Fragment } from "react";
export default class Heading3 extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		let node = this.props.node;
		return (
			<React.Fragment>
				<h3 className="heading-3 headingmainpage card-1">{node.content && node.content[0].value}</h3>
			</React.Fragment>
		);
	}
}
