import React, { Component, Fragment } from "react";
export default class Heading2 extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { node } = this.props;
		return (
			<React.Fragment>
				<h2 className={"heading-2 " + this.props.class} >{node.content && node.content[0].value}</h2>
			</React.Fragment>
		);
	}
}
