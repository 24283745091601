import React, { Component, Fragment } from "react";
import { ImageTag } from "../../dom/ImageTag";
export default class OnBoardingImageHeadingBlock extends React.Component {
	constructor(props) {
		super(props);
	}

	imgLeftAlign = listValue => {
		return (
			<div className="porsche-mission-vision-sec msn-sec">
				<div className="row">
					<div className="col-md-5 col-sm-12">
						<ImageTag className="img-fluid" src={listValue.fields.file && listValue.fields.file.url} islazyLoad={false} />
					</div>
					<div className="col-md-7 col-sm-12">
						<div class="porsche-mission-vision-content">
							<p>{listValue.fields.title} </p>
							<h2 className="heading-2">{listValue.fields.description}</h2>
						</div>
					</div>
				</div>
			</div>
		);
	};

	imgRightAlign = listValue => {
		return (
			<div className="porsche-mission-vision-sec">
				<div className="row flex-column-reverse flex-sm-row">
					<div className="col-md-7 col-sm-12">
						<div class="porsche-mission-vision-content">
							<p>{listValue.fields.title}</p>
							<h2 className="heading-2">{listValue.fields.description}</h2>
						</div>
					</div>
					<div className="col-md-5 col-sm-12">
						<ImageTag className="img-fluid" src={listValue.fields.file && listValue.fields.file.url} isLazyLoad={false} />
					</div>
				</div>
			</div>
		);
	};

	render() {
		const { node } = this.props;
		return (
			<div>
				{
					<div>
						{node.data.target.fields.imageWithHeading.map((listValue, key) => {
							if (key % 2 == 0) {
								return this.imgLeftAlign(listValue);
							} else {
								return this.imgRightAlign(listValue);
							}
						})}
					</div>
				}
			</div>
		);
	}
}
