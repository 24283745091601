import React, { Component, Fragment } from "react";
export default class Heading5 extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { node } = this.props;
		return (
			<React.Fragment>
				<h5 className="heading-5 ">{node.content && node.content[0].value}</h5>
			</React.Fragment>
		);
	}
}
