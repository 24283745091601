/** @format */

import React, { Component, Fragment } from "react";
import client from "../../configuration/Client";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import $ from "jquery";
import "../../css/praktikum.scss";
import ContentFooterImg from "../common/ContentFooterImg";
import ContentHeaderImg from "../common/ContentHeaderImg";
import Footer from "../footerHeader/Footer";
import OnBoardingPerson from "../contentType/onboardingperson/OnBoardingPerson";
import { ImageTag } from "../dom/ImageTag";
import { commonRenderNode } from "../../constant/appConstant";
import HrAcccordion from "../common/hraccordion/HrAcccordion";
import OnBoardingSmallText from "../contentType/onBoardingSmallText/OnBoardingSmallText";
import OnBoardingHeadingInfo from "../contentType/onboardingHeadingInfo/OnBoardingHeadingInfo";
import { Breadcrumb } from "react-bootstrap";
import Heading2 from "../dom/Heading2";
import { BLOCKS } from "@contentful/rich-text-types";

export default class BerufsAndHochschulen extends React.Component {
    constructor(props) {
        super(props);
        const {
            match: { params },
        } = this.props;
        this.state = {
            menuItem: "",
            pageContent: "",
            pageFooter: {},
            pageHeader:JSON.parse(sessionStorage.getItem(params["id"])),
            id: params["id"],
            ids: [],
        };
    }
    componentDidUpdate() {
    }
    componentDidMount() {
        let options = {
            renderNode: {
                ...commonRenderNode,
                ["heading-2"]: (node) => {
                    return (
                        <React.Fragment>
                            <h2
                                id={node.content && node.content[0].value.replace(/\s/g, "")}
                                className="heading-2 menuHeadingmargin mainHead"
                            >
                                {node.content && node.content[0].value}
                            </h2>
                        </React.Fragment>
                    );
                },
                ["paragraph"]: (node) => {
                    // console.log(node)
                    if (node.content && node.content.length == 1) {
                        return (
                            <div style={{ height: "auto" }}>
                                <p class="praghaph-3 praghaph-partikum" style={{ textAlign: "left" }}>
                                    {node.content && node.content[0].value}
                                </p>
                            </div>
                        );
                    }else if(node.content && node.content.length >= 1){
                        return (
                            <div style={{ height: "auto" }}>
                                <p class="praghaph-3 praghaph-partikum" style={{ textAlign: "left" }}>
                                    {node.content && node.content[0].value}
                                    {node.content && node.content[1] && node.content[1].nodeType === "hyperlink"?
                                    (<a className='card-link' href={node.content[1] && node.content[1].data.uri}>{node.content[1] && node.content[1].content[0].value}</a>)
                                    :
                                    ('')
                                    }
                                </p>
                            </div>
                        );
                    }
                },["embedded-entry-block"]: node => {
					if (node.data.target.sys.contentType.sys.id === "onboardingHeadingInfo") {
						return <OnBoardingHeadingInfo node={node} />;
					}else if(node.data.target && node.data.target.sys.contentType.sys.id === "onBoardingSmallText"){
						return <OnBoardingSmallText node={node}/>
					}else if (node.data.target.fields.table) {
						return (
							<div>
							<div className="our-values-sec">
								{
									<div className="row">
										{node.data.target.fields.table.map(function(listValue, key) {
											let digits = listValue.key.substr(0, listValue.key.indexOf("-"));
											let letters = listValue.key.substr(listValue.key.indexOf("-") + 1);
											return (
												<div className="col-12">
													<div>
														<p className="digits">{digits}</p>
														<p style={{}} className="heading-3">
															{letters}
														</p>
														<p className="key_headings" style={{ }} dangerouslySetInnerHTML={{__html: listValue.value}}></p>
													</div>
												</div>
											);
										})}
									</div>
								}
							</div>
							</div>
						);
					} 
				}
            },
        };

        client
            .getEntries({
                content_type: "onboardingPage",
                "sys.id": this.state.id,
                include: 10,
            })
            .then((entry) => {
                if (entry && entry.items) {
                    const { fields } = entry.items[0];
                    if (fields) {
                        this.setState({
                            menuItem: fields.menuItem,
                           // pageHeader: fields.pageHeader,
                            pageFooter: fields.pageFooter,
                            pageContent: documentToReactComponents(fields.pageContent, options),
                            ids: this.breadcrumb(fields.pageContent.content)
                        });
                    }
                    $(".section-container a")
                        .not(".anchor-button-red")
                        .each(function (index) {
                            if (!($(this).attr("href") && $(this).attr("href").length >= 0)) {
                                $(this).parent().addClass("pointer-disabled");
                            }
                        });
                }
            });

        window.scrollTo(0, 0);
    }

    breadcrumb = (content) => {
		let headings = [];
        for (let i = 0; i < content.length; i++) {
            const node = content[i];
            if (node.nodeType === "heading-2") {
                let value = node.content[0].value;
                // split logic for role b praktikum only// last word of heading for breadcum
                if(value.split(" ").length > 1)
                    value = value.split(" ")[value.split(" ").length - 1];

				headings.push({id:node.content[0].value.replace(/\s/g, ""),value:value});
            }
		}
		return headings;
    };
    render() {
        return (
            <div>
            <main className="content-area">
                <main>
                <ContentHeaderImg pageHeader={this.state.pageHeader} />
					<div className="top-section-breadcrumb section-container headingmainpage">
						<div id="breadcrumbmenu">
							<Breadcrumb>
								{this.state.ids.map((item, key) => {
									return <Breadcrumb.Item href={`#${item.id}`}>{item.value}</Breadcrumb.Item>;
								})}
							</Breadcrumb>
						</div>
						
					</div>
					<section className="product-container">
						<div className="content-detail">
							<div className="patrikum-padding-container berufsContainer">
								<div className="pageContents">{this.state.pageContent}</div>
							</div>
						</div>
					</section>
					<div class="clearfix"></div>
                    <ContentFooterImg
                        pageFooter={this.state.pageFooter}
                        pathName={this.props.history.location.pathname}
                    />
                  
                </main>
            </main>
            { this.state.pageContent ? (<Footer pageFooter={this.state.pageFooter} />):('')}
                            </div>
        );
    }
}
