import React, { Component } from "react";
import { ImageTag } from "../../dom/ImageTag";

export default class OnBoardingImageBlock extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { node } = this.props;
		return (
			<div id="imgBlock" style={this.props.style} className="row">
				{node.data.target.fields.images.map((item, key) => {
					if (key == 0) {
						return (
							<div className={this.props.innerChildClass}>
								<ImageTag className="img-pos img-fluid" src={item.fields.file.url} isLazyLoad={true} />
							</div>
						);
					}
				})}
			</div>
		);
	}
}
