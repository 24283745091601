import React from 'react'
import './onboardingsmalltext.scss'

function OnBoardingSmallText({node}) {
    return (
        <div className="section-container porsche-product-container onboarding-smalltext">
            <p>{node.data.target.fields.text}</p>
        </div>
    )
}

export default OnBoardingSmallText
