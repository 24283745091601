import React, { Component, Fragment } from "react";
import "../../css/MainContent.scss";
import "../../css/MainContent-mediaQuerry.scss";
import Footer from "../footerHeader/Footer";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ContentHeaderImg from "../common/ContentHeaderImg";
import ContentFooterImg from "../common/ContentFooterImg";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import client from "../../configuration/Client";
import calendaricon from "../../images/registration.png";
import OnBoardingPerson from "../contentType/onboardingperson/OnBoardingPerson";
import $ from "jquery";
import Heading3 from "../dom/Heading3";
import Heading2 from "../dom/Heading2";
import OnBoardingImageBlock from "../contentType/onboardingImageBlock/OnBoardingImageBlock";
import { ImageTag } from "../dom/ImageTag";
import { commonRenderNode } from "../../constant/appConstant";
import ImgeBarBlock from "../contentType/onboardingImageBar/ImgeBarBlock";
import OnboardingVideoLink from "../contentType/onboardingVideoLink/OnboardingVideoLink";
import LocationBlock from "../contentType/onboardingLocation/ImgeBarBlock";
import OnBoardingTeaser from "../contentType/onboardingTeaser/OnBoardingTeaser";
import OnBoardingSlider from "../contentType/onboardingSlider/OnBoardingSlider";
import HrAcccordion from "../common/hraccordion/HrAcccordion";
import SwipePorsche from "../common/SwipePorsche";
import OnBoardingSmallText from "../contentType/onBoardingSmallText/OnBoardingSmallText";
import OnboardingNumberHeading from "../contentType/onboardingNumberHeading/OnboardingNumberHeading";

export default class PorsheBieMenu extends Component {
	constructor(props) {
		super(props);
		const {
			match: { params }
		} = this.props;
		this.state = {
			open: false,
			menuItem: "",
			pageContent: "",
			pageFooter: {},
			pageHeader: JSON.parse(sessionStorage.getItem(params["id"])),
			id: params["id"]
		};
	}

	componentDidUpdate() {}
	componentDidMount() {
		let options = {
			renderNode: {
				...commonRenderNode,
				["heading-2"]: node => {
                    // console.log("1111111111 ", node);
					return (
						<div className="justSay">
							<Heading2 node={node} />
						</div>
					);
				},
				["paragraph"]: node => {
					if(node.data.target && node.data.target.sys.contentType.sys.id === "onBoardingSmallText"){
						return <OnBoardingSmallText node={node}/>
					}
					else if (node.content && node.content.length == 1) {
						return (
							<div style={{ height: "auto" }}>
								<p class="praghaph-3 margin-bottom-40"  style={{ textAlign: "left" }}>
									{node.content && node.content[0].value}
								</p>
							</div>
						);
					} else if (node.content && node.content.length > 1) {
						if (
							node.content[1].nodeType === "embedded-entry-inline" &&
							node.content[1].data.target.sys.contentType.sys.id === "onBoardingProduct"
						) {
							return (
								<React.Fragment>
									<SwipePorsche data={node.content} />
								</React.Fragment>
							);
						} else if (
							node.content[1].nodeType === "embedded-entry-inline" &&
							node.content[1].data.target.sys.contentType.sys.id === "onboardingAccordion"
						) {
							return (
								<div className="btm-acr-container">
									<div>
										<HrAcccordion pageContent={node} />
									</div>
								</div>
							);
						} else if (
							node.content[1].nodeType === "embedded-entry-inline" &&
							node.content[1].data.target.sys.contentType.sys.id === "onboardingNumberHeadingText"
						) {
							return (
								<div>
									<OnboardingNumberHeading node={node.content} />
								</div>
							);
						} else if (node.content[1].nodeType === "embedded-entry-inline" &&
							node.content[1].data.target.sys.contentType.sys.id === "onboardingLocation") {
							return (
								<div className="section-container card-section-container">
									<LocationBlock pageContent={node} />
								</div>
							);
						}
					}
				},
                ["embedded-entry-block"]: node => {
					if(node.data.target && node.data.target.sys.contentType.sys.id === "onBoardingSmallText"){
						return <OnBoardingSmallText node={node}/>
					}
					else if (node.data.target.fields && node.data.target.fields.images) {
						if (node.data.target.fields.images.length > 1) {
							return (
								<div className="picture-gallery">
									<div className="row">
										{node.data.target.fields.images.map(function (listValue, key) {
											return (
												<div className="col-sm-4">
													<ImageTag className="img-fluid" src={listValue.fields.file.url} islazyLoad={false} />
												</div>
											);
										})}
									</div>
								</div>
							);
						} else if (node.data.target.fields.images.length == 1) {
							return (
								<div className="picture-gallery-border">
									<ImageTag
										className="img-fluid"
										src={node.data.target.fields.images && node.data.target.fields.images[0].fields.file.url}
										isLazyLoad={true}
									/>
								</div>
							);
						}
					} else if (node.data.target && node.data.target.sys.contentType.sys.id === "onboardingSlider") {
						return (
							<div>
								<OnBoardingSlider node={node} />
							</div>
						);
					} else if (node.data.target && node.data.target.sys.contentType.sys.id === "onboardingTeaser") {
						return (
							<div>
								<OnBoardingTeaser node={node} />
							</div>
						);
					} else if (node.data.target && node.data.target.sys.contentType.sys.id === "onboardingVideoLink") {
						// console.log(node)
						return (
							<div class="videoPlaybutton">
								<OnboardingVideoLink node={node} />
							</div>
						);
					}
				}
			}
		};

		client
			.getEntries({
				content_type: "onboardingPage",
				"sys.id": this.state.id,
				include: 10
			})
			.then(entry => {
				const { fields } = entry.items[0];
				if (fields) {
					this.setState({
						menuItem: fields.menuItem,
						//pageHeader: fields.pageHeader,
						pageFooter: fields.pageFooter,
						pageContent: documentToReactComponents(fields.pageContent, options)
					});
				}
			});
		window.scrollTo(0, 0);
	}
	onOpenModal = () => {
		this.setState(prevState => ({
			open: !prevState.open
		}));
	};

	moveToDocs = link => {
		//TODO
		link = "docs";
		const menuData = JSON.parse(sessionStorage.getItem("menuData"));
		let doc;
		for (let i = 0; i < menuData.length; i++) {
			if (menuData[i].templateName.indexOf(link) >= 0) {
				doc = menuData[i];
				break;
			}
		}

		return doc.path + "/" + doc.id;
	};
	render() {
		return (
			<div>
			<main className="content-area">
				<main>
					<ContentHeaderImg pageHeader={this.state.pageHeader} />
					<div className="content-detail">
						<div className="welcome-content-detail bieMenuPage">
							<div className="pageContents lr-paading-container">{this.state.pageContent}</div>
						</div>
					</div>
					<ContentFooterImg pageFooter={this.state.pageFooter} pathName={this.props.history.location.pathname} />
				</main>
			</main>
			{ this.state.pageContent ? (<Footer pageFooter={this.state.pageFooter} />):('')}
				
			</div>
		);
	}
}