import React, { Component, Fragment } from "react";
export default class Heading4 extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		let node = this.props.node;
		return (
			<React.Fragment>
				<div className="section-container" style={{ height: "auto", margin: "59px 0px 0px 0px" }}>
					<h4 className="heading-4 primary-color">{node.content && node.content[0].value}</h4>
				</div>
			</React.Fragment>
		);
	}
}
