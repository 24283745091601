/** @format */

import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Route, NavLink, Link, BrowserRouter as Router } from "react-router-dom";
import "../../css/Navigation.scss";
import client from "../../configuration/Client";
import { appConstant, REGEX_MENU_GERMEN_TITLE, REGEX_MENU_SERIAL_NO } from "../../constant/appConstant";
import logout from "../../images/logout.png";
import team from "../../images/team.png";
import $ from "jquery";
export default class Navigation extends Component {
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		if ($(".dropselect").length) {
			$(".dropselect").off("click");
		}

		$(document).ready(function () {
			setTimeout(() => {
				$(".navbar-toggler").click(function () {
					$(".navbar").toggleClass("nav-show");
				});
				$(".navbar .navbar-nav>li>a").on("click", function () {
					$(".navbar-collapse").collapse("hide");
					$(".navbar").removeClass("nav-show");
				});

				$(".dropselect").click(function () {
					if (
						$("#dropicon")
							.attr("class")
							.includes("icon-rotate")
					) {
						$("#dropnavbar").removeClass("show");
						$("#dropicon").removeClass("icon-rotate");
					} else {
						$("#dropnavbar").addClass("show");
						$("#dropicon").addClass("icon-rotate");
					}
				});

				$(".nav-item").click(function () {
					$(".dropselect .select-val")
						.find("span")
						.eq(0)
						.text(
							$(this)
								.find("span")
								.eq(0)
								.text()
						);

					$(".dropselect .select-val")
						.find("span")
						.eq(1)
						.text(
							$(this)
								.find("span")
								.eq(1)
								.text()
						);

					if (
						$("#dropicon")
							.attr("class")
							.includes("icon-rotate")
					) {
						$("#dropnavbar").removeClass("show");
						$("#dropicon").removeClass("icon-rotate");
					} else {
						$("#dropnavbar").addClass("show");
						$("#dropicon").addClass("icon-rotate");
					}
				});

			}, 800);

		});
	}
	changeTeamHandler = () => {
		this.props.changeTeamHandler();
		window.location.href = "/";
	};
	componentDidUpdate() {

	}

	render() {
		let digit =
			this.props.menuData &&
			this.props.menuData
				.filter(item => `${item.path}/${item.id}` === window.location.pathname)
				.map((item, key) => {
					return item.name.match(REGEX_MENU_SERIAL_NO);
				});

		let letter =
			this.props.menuData &&
			this.props.menuData
				.filter(item => `${item.path}/${item.id}` === window.location.pathname)
				.map((item, key) => {
					return item.name.match(REGEX_MENU_GERMEN_TITLE);
				});
		return (
			<nav className="navbar navbar-expand-lg float-left  left-panel-bar asideBar">
				<div class="dropdown-navbar">
					<div class="dropselect">
						<span className="select-val">
							<span className="select-num">{digit}</span>
							<span>{letter}</span>
						</span>
						{/* icon-rotate when dropdown-content has class show */}
						<span className="drop-icon icon-arrow-down" id="dropicon"></span>
					</div>
					{/* dropdown-content class show on drop-select click*/}
					<div class="dropdown-content" id="dropnavbar">
						<ul className="navbar-nav">
							{this.props.menuData &&
								this.props.menuData.map((item, key) => {
									let digitPattern = REGEX_MENU_SERIAL_NO;
									let digits = item.name.match(digitPattern);
									let letterPattern = REGEX_MENU_GERMEN_TITLE; //
									let letters = item.name.match(letterPattern);
									return (
										<li className="nav-item" key={key}>
											<NavLink exact to={item.path + "/" + item.id} activeClassName="active-link" className="nav-link navbar-link">
												<span>{digits}</span>
												<span>{letters}</span>
											</NavLink>
										</li>
									);
								})}
						</ul>
					</div>
					<div className="background-drop"></div>
				</div>
				<div className="collapse navbar-collapse navigation-color" id="navbarCollapse">
					<div className="header-left header-text-pd-tp">
						{/* {appConstant.MY_PORSCHE}  */}
						{appConstant.WELCOME_GUIDE}
					</div>
					<ul className="navbar-nav">
						{this.props.menuData &&
							this.props.menuData.map((item, key) => {
								let digitPattern = REGEX_MENU_SERIAL_NO;
								let digits = item.name.match(digitPattern);
								let letterPattern = REGEX_MENU_GERMEN_TITLE; //
								let letters = item.name.match(letterPattern);
								return (
									<li className="nav-item" key={key}>
										<NavLink exact to={item.path + "/" + item.id} activeClassName="active-link" className="nav-link navbar-link">
											<span>{digits}</span>
											<span>{letters}</span>
										</NavLink>
									</li>
								);
							})}
					</ul>
				</div>
			</nav>
		);
	}
}
