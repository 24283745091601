import React, { Component, Fragment } from "react";
export default class HrTag extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { node } = this.props;
		return (
			<React.Fragment>
				<div className="hrseptop">
					<hr ></hr>
				</div>
			</React.Fragment>
		);
	}
}
