/** @format */

import React, { Component, Fragment } from "react";
import client from "../../configuration/Client";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import $ from "jquery";
import "../../css/MyPorsche.scss";
import ContentFooterImg from "../common/ContentFooterImg";
import ContentHeaderImg from "../common/ContentHeaderImg";
import Footer from "../footerHeader/Footer";
import OnBoardingPerson from "../contentType/onboardingperson/OnBoardingPerson";
import { ImageTag } from "../dom/ImageTag";
import { commonRenderNode } from "../../constant/appConstant";
import HrAcccordion from "../common/hraccordion/HrAcccordion";

import { Breadcrumb } from "react-bootstrap";
import Heading2 from "../dom/Heading2";
import { BLOCKS } from "@contentful/rich-text-types";

export default class MyPorscheMenu extends React.Component {
    constructor(props) {
        super(props);
        const {
            match: { params },
        } = this.props;
        this.state = {
            menuItem: "",
            pageContent: "",
            pageFooter: {},
            pageHeader:JSON.parse(sessionStorage.getItem(params["id"])),
            id: params["id"],
            ids: [],
        };
    }
    componentDidUpdate() {
        var j = document.getElementsByClassName("PorscheTeamsGrp")[0];
        if (
            document.getElementsByClassName("PorscheTeams") &&
            document.getElementsByClassName("PorscheTeams").length > 1
        ) {
            for (let i = 0; i < document.getElementsByClassName("PorscheTeams").length; i++) {
                var k = document.getElementsByClassName("PorscheTeamsGrp")[0];
                k.appendChild(document.getElementsByClassName("PorscheTeams")[i]);
            }
        }
    }
    componentDidMount() {
        let options = {
            renderNode: {
                ...commonRenderNode,
                ["heading-2"]: (node) => {
                    return (
                        <React.Fragment>
                            <h2
                                id={node.content && node.content[0].value.replace(/\s/g, "")}
                                className="heading-2 menuHeadingmargin"
                            >
                                {node.content && node.content[0].value}
                            </h2>
                        </React.Fragment>
                    );
                },
                ["paragraph"]: (node) => {
                    if (node.content && node.content.length == 1) {
                        return (
                            <div style={{ height: "auto" }}>
                                <p class="praghaph-3" style={{ textAlign: "left" }}>
                                    {node.content && node.content[0].value}
                                </p>
                            </div>
                        );
                    } else if (node.content && node.content.length > 1) {
                        {
                            if (
                                node.content[1].nodeType === "embedded-entry-inline" &&
                                node.content[1].data.target.sys.contentType.sys.id == "onboardingPerson"
                            ) {
                                return (
                                    <React.Fragment>
                                        <OnBoardingPerson isEntryBlock={false} pageContent={node} />
                                    </React.Fragment>
                                );
                            } else if (
                                node.content[1].nodeType === "embedded-entry-inline" &&
                                node.content[1].data.target.sys.contentType.sys.id === "onboardingAccordion"
                            ) {
                                return (
                                    <div className="btm-acr-container">
                                        <div className="benifitsAccr">
                                            <HrAcccordion pageContent={node} />
                                        </div>
                                    </div>
                                );
                            }
                        }
                    }
                },
                ["embedded-asset-block"]: (node) => {
                    if (node.data.target.fields && node.data.target.fields.file.url) {
                        return (
                            <div className="picture-gallery-border">
                                <ImageTag
                                    className="img-fluid "
                                    src={node.data.target.fields.file.url}
                                    isLazyLoad={true}
                                />
                            </div>
                        );
                    }
                },
                ["embedded-entry-block"]: (node) => {
                    if (node.data.target.fields && node.data.target.fields.images) {
                        if (node.data.target.fields.images.length > 1) {
                            return (
                                <div className="picture-gallery">
                                    <div className="row">
                                        {node.data.target.fields.images.map(function (listValue, key) {
                                            return (
                                                <div className="col-sm-4">
                                                    <ImageTag
                                                        className="img-fluid"
                                                        src={listValue.fields.file.url}
                                                        islazyLoad={false}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        } else if (node.data.target.fields.images.length == 1) {
                            return (
                                <div className="picture-gallery-border lr-paading-container">
                                    <ImageTag
                                        className="img-fluid "
                                        src={
                                            node.data.target.fields.images &&
                                            node.data.target.fields.images[0].fields.file.url
                                        }
                                        isLazyLoad={true}
                                    />
                                </div>
                            );
                        }
                    }
                },
            },
        };

        client
            .getEntries({
                content_type: "onboardingPage",
                "sys.id": this.state.id,
                include: 10,
            })
            .then((entry) => {
                if (entry && entry.items) {
                    const { fields } = entry.items[0];
                    if (fields) {
                        this.setState({
                            menuItem: fields.menuItem,
                           // pageHeader: fields.pageHeader,
                            pageFooter: fields.pageFooter,
                            pageContent: documentToReactComponents(fields.pageContent, options),
                            ids: this.breadcrumb(fields.pageContent.content)
                        });
                    }
                    $(".section-container a")
                        .not(".anchor-button-red")
                        .each(function (index) {
                            if (!($(this).attr("href") && $(this).attr("href").length >= 0)) {
                                $(this).parent().addClass("pointer-disabled");
                            }
                        });
                }
            });

        window.scrollTo(0, 0);
    }

    breadcrumb = (content) => {
		let headings = [];
        for (let i = 0; i < content.length; i++) {
            const node = content[i];
            if (node.nodeType === "heading-2") {
				headings.push({id:node.content[0].value.replace(/\s/g, ""),value:node.content[0].value});
            }
		}
		return headings;
    };
    render() {
        return (
            <div>
            <main className="content-area">
                <main>
                    <ContentHeaderImg pageHeader={this.state.pageHeader} />
                    <section className="product-container">
                        <div className="top-section-breadcrumb section-container headingmainpage">
							<div className="title-1">
								<span>Schnell zu</span>
							</div>
							<div id="breadcrumbmenu">
								<Breadcrumb>
									{this.state.ids.map((item, key) => {
										return <Breadcrumb.Item href={`#${item.id}`}>{item.value}</Breadcrumb.Item>;
									})}
								</Breadcrumb>
							</div>
							<div className="seperator"></div>
						</div>
						<div className="content-detail">
                            <div className="welcome-content-detail">
                                <div className="pageContents section-container product-container">{this.state.pageContent}</div>
                            </div>
                        </div>
                    </section>
                    <div class="clearfix"></div>
                    <ContentFooterImg
                        pageFooter={this.state.pageFooter}
                        pathName={this.props.history.location.pathname}
                    />
                  
                </main>
            </main>
            { this.state.pageContent ? (<Footer pageFooter={this.state.pageFooter} />):('')}
                            </div>
        );
    }
}
