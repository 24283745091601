import React, { Component, Fragment } from "react";
export default class OnBoardingTable extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const fields = this.props.node.data.target.fields;
		return (
			<div className="card-1 table-value-sec">
				{fields.table &&
					fields.table.map(function(listValue, key) {
						return (
							<div className="table-value">
								<div class="row">
									<div class="col-sm-6">
										<p className="table-item">{listValue.key}</p>
									</div>
									<div class="col-sm-6">
										<p className="table-item-right">{listValue.value}</p>
									</div>
								</div>
							</div>
						);
					})}
			</div>
		);
	}
}
