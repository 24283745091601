import React from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { ImageTag } from '../../dom/ImageTag';
import './onboardingLocationModel.scss';
import pdficon from '../../../images/pdfdownload.png'
import mapicon from '../../../images/mapicon.png'

function OnboardingLocationModel(props) {
    

  return (  <Modal {...props} className="bar-block-modal" aria-labelledby="contained-modal-title-vcenter">
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        { props.content.title}
      </Modal.Title>
    </Modal.Header>
 
    <div className="container bar-block-modal-container">
        <div className="row">
            <div className="col-md-5 col-12 image-block padding0">
                <div className="row">
                    <ImageTag className="img-fluid " src={props.content.locationImage && props.content.locationImage.fields.file.url} isLazyLoad={false} />
                </div>
                { props.content 
                && props.content.FileLocationMapping
                && props.content.FileLocationMapping.map((item,index)=>{
                    return (
                    <>
                    <div className="row bar-image-link pdfrow">
                        <a href={item.fields.fileLocation.fields.file.url} target="_blank"> <img src={pdficon} className="baricon-image"/> <span>{item.fields.fileLocation.fields.title}</span> </a>
                    </div>
                      <div className="row bar-image-link maplinkrow">
                      <a href={item.fields.locationlink} target="_blank"> <img src={mapicon} className="baricon-image"/> <span>{item.fields.locationLinkTitle}</span> </a>
                      </div></>)

                }) }


            </div>
            <div className="col-md-7 text-block padding0 col-12">
                <p>
                { props.content.description}
                </p>    
            </div>
        </div>  
    </div>    

  </Modal>
);
}

export default OnboardingLocationModel
