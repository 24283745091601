import client from "../../configuration/Client";
import ContentFooterImg from "../common/ContentFooterImg";
import ContentHeaderImg from "../common/ContentHeaderImg";
import React, { Component, Fragment } from "react";
import Footer from "../footerHeader/Footer";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import "../../css/HowWeWork.scss";
import OnBoardingHeadingInfo from "../contentType/onboardingHeadingInfo/OnBoardingHeadingInfo";
import $ from "jquery";
import ModelPorsche from "../common/ModelPorsche";
import SwipePorsche from "../common/SwipePorsche";
import Heading3 from "../dom/Heading3";
import { ImageTag } from "../dom/ImageTag";
import { commonRenderNode } from "../../constant/appConstant";
import OnBoardingSmallText from "../contentType/onBoardingSmallText/OnBoardingSmallText";
import { BLOCKS } from "@contentful/rich-text-types";
import Heading6 from "../dom/Heading6";

export default class PorshceProduct extends React.Component {
	constructor(props) {
		super(props);
		const {
			match: { params }
		} = this.props;
		this.state = {
			menuItem: "",
			showComponent: false,
			modelData: "",
			pageContent: "",
			pageFooter: {},
			pageHeader: JSON.parse(sessionStorage.getItem(params["id"])),
			timelineIndex: 0,
			swipeData: [],
			paragraphLoopIndex: 0,
			id: params["id"]
		};
		this._onButtonClick = this._onButtonClick.bind(this);
		this.handler = this.handler.bind(this);
	}
	_onButtonClick = ev => {
		this.setState({
			showComponent: true,
			timelineIndex: `${ev.currentTarget.dataset.div_id}`
		});
	};

	handler() {
		this.setState({
			showComponent: false
		});
	}

	componentDidUpdate() {
		if (!$(".cubeSpinner").length) {
			$(".swipe-data-center")
				.prev(".card-1")
				.addClass("swipe-data-center");
			$(".swipe-data-center")
				.prev(".card-1")
				.prev(".card-1")
				.addClass("swipe-data-center");
			$(".swipe-data-center").wrapAll('<div class="cubeSpinner"></div>');
		}
	}

	componentDidMount() {
		let options = {
			renderNode: {
				...commonRenderNode,
				[BLOCKS.HR]: node => {
					return "";
				  },
				  [BLOCKS.HEADING_6]: node => {
					return <Heading6 classes="porsche-product-container heading-6 smallheading section-container" node={node} />;
				  },
				["paragraph"]: node => {
				 
						//normail text will flow where the is no other refrenced attached
						return (
							<div className="card-1 padding-100" style={{ height: "auto" }}>
								<p class="praghaph-3" style={{ textAlign: "left" }}>
									{node.content && node.content[0].value}
								</p>
							</div>
						);
					
				},
				["heading-3"]: node => {
					return (
						<div className="section-container">
							<div className="" style={{ height: "auto", marginTop: "115px" }}>
								<Heading3 node={node} />
							</div>
						</div>
					);
				},
				["embedded-asset-block"]: node => {
					return (
						<div className="principle-imc-sec padding-100">
							<ImageTag className="img-fluid" src={node.data.target.fields.file.url} isLazyLoad={true}/>
						</div>
					);
				},

				["embedded-entry-inline"]: node => {
					return <div class="vertical-timeline card-1"></div>;
				},

				["embedded-entry-block"]: node => {
					if (node.data.target.sys.contentType.sys.id === "onboardingHeadingInfo") {
						return <OnBoardingHeadingInfo node={node} />;
					}else if(node.data.target && node.data.target.sys.contentType.sys.id === "onBoardingSmallText"){
						return <OnBoardingSmallText node={node}/>
					}else if (node.data.target.fields.table) {
						return (
							<div className="section-container porsche-product-container">
							<div className="our-values-sec">
								{
									<div className="row">
										{node.data.target.fields.table.map(function(listValue, key) {
											let digits = listValue.key.substr(0, listValue.key.indexOf("-"));
											let letters = listValue.key.substr(listValue.key.indexOf("-") + 1);

											return (
												<div className="col-12">
													<div className="bor-tp-card">
														<p className="digits">{digits}</p>
														<p style={{}} className="heading-3">
															{letters}
														</p>
														<p className="key_heading bulletPoints" style={{ }} dangerouslySetInnerHTML={{__html: listValue.value}}></p>
													</div>
												</div>
											);
										})}
									</div>
								}
							</div>
							</div>
						);
					} 
				}
			}
		};
		client
			.getEntries({
				content_type: "onboardingPage",
				"sys.id": this.state.id,
				include: 10
			})
			.then(entries => {
				const { fields } = entries.items[0];
				if (fields) {
					this.setState({
						menuItem: fields.menuItem,
						//pageHeader: fields.pageHeader,
						pageFooter: fields.pageFooter,
						pageContent: documentToReactComponents(fields.pageContent, options)
					});
				}
			});
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<div>
			<main className="content-area">
				<main>
					<ContentHeaderImg pageHeader={this.state.pageHeader} />
					{this.state.showComponent ? (
						<ModelPorsche action={this.handler} index={this.state.timelineIndex} show={true} modelData={this.state.modelData} />
					) : (
						""
					)}
					<section className="section-container product-container">
						<div className="content-detail">
							<div className="welcome-content-detail">
								<div className="pageContents">{this.state.pageContent}</div>
							</div>
						</div>
					</section>
					<div class="clearfix"></div>
					<ContentFooterImg pageFooter={this.state.pageFooter} pathName={this.props.history.location.pathname} />
				</main>
			</main>
			{ this.state.pageContent ? (<Footer pageFooter={this.state.pageFooter} />):('')}
			</div>			
		);
	}
}
