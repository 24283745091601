import React, { Component, Fragment } from 'react';
import playBtn from '../../Icon-Play@2x.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ImageTag } from '../dom/ImageTag';

export default class ContentHeaderImg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            src: '',
            open: false
        }
    }
    closeVideo = () => {
        this.setState({
            src: ''
        })
    }
    playVideo = () => {
        let link = "";
        if (this.props.pageHeader.fields && this.props.pageHeader.fields.videoLink) {
            link = this.props.pageHeader.fields.videoLink;
            if (this.props.pageHeader.fields.videoLink.indexOf("vimeo.com") > 0) {
                let index = link.lastIndexOf("/") + 1;
                link = "https://player.vimeo.com/video/" + link.substr(index);
            }
        }
        this.setState({
            src: this.props.pageHeader.fields && link + "?autoplay=1"
        })
    }
    render() {
        let videoButton;
        let videoLinkText;
        let videoModal
        if (this.props.pageHeader.fields && this.props.pageHeader.fields.videoLink) {
            videoButton = <span className="video-icon" data-toggle="modal" onClick={this.playVideo} data-target="#videoModal"><i className="icon-play"></i></span>

            videoModal = (
                <div className="modal fade" id="videoModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="videoModal" aria-hidden="true">
                    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content video-modal" >
                            <div className="modal-body" >
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={this.closeVideo}>&times;</button>
                                <div>
                                    <iframe id="iframeVideo" width="100%" height="350" src={this.state.src} allowFullScreen allow="autoplay"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        if (this.props.pageHeader.fields && this.props.pageHeader.fields.linkText) {
            videoLinkText = <label className="more-dtl" data-toggle="modal" onClick={this.playVideo} data-target="#videoModal" style={{ paddingLeft: '10px' }}>{this.props.pageHeader.fields && this.props.pageHeader.fields.linkText}</label>
        }
        if (this.props.pageHeader) {
            const video = this.props.pageHeader.fields && this.props.pageHeader.fields.linkText;
            return (
                <div className="content-header">
                    <div className="content-header-background"></div>

                    {/* <ImageTag className="img-fluid" src={this.props.pageHeader.fields && this.props.pageHeader.fields.image.fields.file.url} isLazyLoad={false}/> */}
                    <img className="img-fluid" src={this.props.pageHeader.fields && this.props.pageHeader.fields.image.fields.file.url + "?fm=jpg"} />
                    <div className='section-container top-section-breadcrumb content-header-img '>
                        <h2 >{this.props.pageHeader.fields && this.props.pageHeader.fields.headline}</h2>
                        {videoButton}
                        {videoLinkText}
                        {videoModal}
                    </div>
                </div>

            )
        }
    }
}