import React, { Component } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';

export class ImageTag extends Component {

    constructor(props) {
        super(props)
    }
    
    lazyLoad(src,className){
        return <LazyLoadImage class={className} src={src} />
    }

    eagerLoad(src,className){
        return <img className={className} data-src={this.props.dataSrc} src={src} />
    }

    render() {
        const {className,isLazyLoad,src}=this.props;
        const content=isLazyLoad?this.lazyLoad(src,className): this.eagerLoad(src,className);
        return (
            <React.Fragment>
               {content}
            </React.Fragment>
        )
    }
}

