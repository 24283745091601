/** @format */

import React, { Component } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import './hraccordion.scss';
import { ImageTag } from "../../dom/ImageTag";

class HrAcccordion extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }
    rotateIcon(id) {
        const $ = window.$;
        
            if (
                $(`#dropicon${id}`)
                    .attr("class")
                    .includes("icon-rotate")
            ) {
                $(`#dropicon${id}`).removeClass("icon-rotate");
            } else {
                $('[id^="dropicon"]').each(function () {
                  $(`#${this.id}`).removeClass("icon-rotate");
                });
                $(`#dropicon${id}`).addClass("icon-rotate");
            }
    }

    render() {
        let arrayest = new Array();
        for (var item in this.props.pageContent) {
            if (item == "content") {
                arrayest = this.props.pageContent[item];
            }
        }

        let data = [];

        for (let i = 0; i <= arrayest.length - 1; i++) {
            
            if (
                arrayest[i].nodeType === "embedded-entry-inline" &&
                arrayest[i].data.target.sys.contentType.sys.id === "onboardingAccordion"
            ) {
                let node = arrayest[i];
                let title = node.data.target.fields.name;
                
                let id=Math.floor((Math.random() * 1000));
                data.push(
                        <Card key={i}>
                            <Accordion.Toggle
                                as={Card.Header}
                                className="accordionButton  "
                                variant="link"
                                eventKey={id+i}

                               onClick={()=>this.rotateIcon(id+i) }
                            >
                                <div className="row " >
                                    <div className="col-10" style={{ fontSize: '18px', lineHeight: '30px' }}>{title}</div>
                                    <div className="col-2 accr-btn-box" >
                                    <span className="drop-icon icon-arrow-down " id={`dropicon${id+i}`}></span>  
                                    </div>
                                </div>

                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={id+i}>
                                
                                <Card.Body style={{ fontSize: '18px', lineHeight: '30px' }}>
                                    {node.data.target.fields.description.content[0].content.map((content, key) => {   
                                        if (content.nodeType === "text") {
                                            return content.value;
                                        } else if (content.nodeType === "hyperlink") {
                                            let email = `mailto:${content.data.uri}`
                                            return (<Card.Link href={email}>{content.data.uri}</Card.Link>)
                                        }
                                    })}
                                    <br></br>{
                                    (node.data.target.fields && node.data.target.fields.images) ? 
                                                <ImageTag
                                                    className="img-fluid"
                                                    src={node.data.target.fields.images.fields.file.url}
                                                    islazyLoad={false}
                                                /> : null
                                    }
                                    {
                                    (node.data.target.fields && node.data.target.fields.descriptionData) ? 
                                               <span><br></br>{node.data.target.fields.descriptionData[0].value}</span>                
                                                 : null
                                    } 
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                );
            }
        }
        return (
            <div>
                <Accordion  >
                    {data.map((content, key) => {
                        return content;
                    })}
                </Accordion>
            </div>
        );
    }
}

export default HrAcccordion;
