/** @format */

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ImageTag } from "../../dom/ImageTag";
import OnboardingLocationModel from "../onboardingLocationModel/OnboardingLocationModel";

function LocationBlock(props) {

	const [modalShow, setModalShow] = useState(false);
	const [content,setContent] = useState({})

	return (
		<>
		<div className="row margin0">
			{props.pageContent.content.map((item, key) => {
				if (item.nodeType === "embedded-entry-inline" && item.data.target.sys.contentType.sys.id == "onboardingLocation") {
					const fields = item.data.target.fields;
					return (
						<div className="col-sm-6 padding-left5 col-12">
							<div className="row card-image-box">
								<div className="card-image">
									<ImageTag className="img-fluid " src={fields.thumbnail && fields.thumbnail.fields.file.url} isLazyLoad={true} />
								</div>
								<div className="support-content-area">
									<a style={{ color: "inherit" }} href="#" className="" onClick={() =>{ setModalShow(true);setContent(fields) ;}}>
										<i class="icon-arrow-right"></i>
										<span>{fields.heading}</span>
									</a>
								</div>
							</div>
						</div>
					);
				}
			})}

		</div>
		 <OnboardingLocationModel show={modalShow} onHide={() => setModalShow(false)} size="xl" content={content}/>

		</>
	);
}

export default LocationBlock;
