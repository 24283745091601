import React, { Component, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import $ from "jquery";
import Carousel from "react-bootstrap/Carousel";
import noImage from "../../images/placeholder.jpg";
import { ImageTag } from "../dom/ImageTag";

export default class ModelPorsche extends React.Component {
	constructor(props) {
		super(props);

		let carouslList = [];
		this.props.modelData.map(function (value, key) {
			if (value.nodeType === "embedded-entry-inline" && value.data.target.sys.contentType.sys.id == "onboardingImageHeadingBlock") {
				if (value.data.target && value.data.target.fields) {
					value.data.target.fields.imageWithHeading &&
						value.data.target.fields.imageWithHeading.map(function (listValue, key) {
							if (listValue.fields.description && listValue.fields.description.length > 1) {
								listValue["name"] = value.data.target.fields.title;
								listValue["image"] =
									value.data.target.fields.titleImage &&
									value.data.target.fields.titleImage.fields &&
									value.data.target.fields.titleImage.fields.file.url;
								carouslList.push(listValue);
							}
						});
				}
			}
		});
		this.handleSelect = this.handleSelect.bind(this);
		this.state = {
			show: true,
			carouslList: carouslList,
			index: this.props.index ? Number(this.props.index) : 0
		};
		console.log(" carouslList", carouslList);
	}

	componentDidMount() {

	}
	handleSelect = (selectedIndex, e) => {
		this.setState({
			index: selectedIndex
		});
	};

	render() {
		return (
			<div className="col-sm-4 col-6">
				<Modal className="product-modal pawan-text" show={this.props.show} onHide={this.props.action}>
					<div className="product-modal-content">
						<div className="section-container">

						</div>
						<div className="discription-section">
							<div className="text-title-section">
								<div className="small-title">
									<span>{this.state.carouslList[this.state.index].fields.title}</span>
								</div>
								<div className="title-main">
									<ImageTag className="img-fluid timeline-head-icon" src={this.state.carouslList[this.state.index].image} isLazyLoad={false} />
									<span className="heading-3">{this.state.carouslList[this.state.index].name}</span>
								</div>
							</div>
							<div className="text-discription-section praghaph-3">
								<span>{this.state.carouslList[this.state.index].fields.description}</span>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

