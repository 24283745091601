import React, { Component, Fragment } from "react";
export default class BlackQuote extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		let node = this.props.node;
		return (
			<React.Fragment>
				<div className={`marg-60 mission-sec ${this.props.class}`} style={this.props.style}>
					<h6 className="heading-2">{node.content && node.content[0].content[0].value}</h6>
				</div>
			</React.Fragment>
		);
	}
}
