import React, { Component } from "react";
import "./App.scss";
import "./index.scss";
import client from "./configuration/Client";
import Header from "./components/footerHeader/Header";
import ContentNavigation from "./components/navigation/Navigation";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PorschePrincipleMenu from "./components/menuItems/PorschePrincipleMenu";
import WelcomeMenu from "./components/menuItems/WelcomeMenu";
import SelectedTeam from "./components/common/SelectTeam";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import MyPorscheMenu from "./components/menuItems/MyPorscheMenu";
import HowWeWrokMenu from "./components/menuItems/HowWeWrokMenu";
import YourDocumentsMenu from "./components/menuItems/YourDocumentsMenu";
import SupportMenu from "./components/menuItems/SupportMenu";
import PorshceProduct from "./components/menuItems/PorshceProduct";
import PorsheBieMenu from "./components/menuItems/PorsheBieMenu";
import "bootstrap/dist/css/bootstrap.min.css";
import { withRouter } from "react-router-dom";
import Map from './components/menuItems/map'
import { Alert } from "bootstrap";
import Login from "./components/login/Login";
import PraktikumAndAbschlussarbeit from "./components/menuItems/PraktikumAndAbschlussarbeit";
import BerufsAndHochschulen from "./components/menuItems/BerufsAndHochschulen";

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			allTeams: [],
			menuItems: [],
			pageContent: "",
			teamSelected: sessionStorage.getItem("selectedTeam") && sessionStorage.getItem("selectedTeam") != "null" ? true : false,
			selectTeamFooter: {},
			pageHeader: {},
			chnageEvent: 0,
			firstMenuItemToDisplay: {},
			counter: 0,
			menuItems: []
		};

		if (sessionStorage.getItem("selectedTeam") && sessionStorage.getItem("selectedTeam") != "null") {

			this.getMenuIteamForSelctedTeam();
			const menuData = JSON.parse(sessionStorage.getItem("menuData"));
			menuData.map((item,k)=>{
				this.setHeaderInSession(item.id);
			});
		}
	}


    setHeaderInSession=(id)=>{
        if(id){
		client
			.getEntries({
				content_type: "onboardingPage",
				"sys.id": id,
				select: "fields.pageHeader",
				include: 10
			})
			.then(entry => {
				const { fields } = entry.items[0];
				if (fields) {
                    sessionStorage.setItem(id, JSON.stringify(fields.pageHeader));
				}
            });
        }
	}
	
	getMenuIteamForSelctedTeam = () => {
		return new Promise((resolve, reject) => {
			let firstMenuPage = {};
			let menuData = [];
			client
				.getEntries({
					content_type: "onboardingTeams",
					"fields.name": sessionStorage.getItem("selectedTeam"),
					select: "fields.menuItems",
					include: 10
				})
				.then(entries => {
					entries.items.forEach((entry, key) => {
						for (var index = 0; index < entries.items[0].fields.menuItems.length; index++) {
							let obj = {
								id: entries.items[0].fields.menuItems[index].sys.id,
								name: entries.items[0].fields.menuItems[index].fields.menuItem,
								templateName:entries.items[0].fields.menuItems[index].fields.templateName
							};
							if (obj.templateName.indexOf("welcome") >= 0) {
								obj["path"] = "/welcome";
							} else if (obj.templateName.indexOf("principle") >= 0) {
								obj["path"] = "/principle";
							} else if (obj.templateName.indexOf("product") >= 0) {
								obj["path"] = "/product";
							} else if (obj.templateName.indexOf("howwework") >= 0) {
								obj["path"] = "/howweworkmenu";
							} else if (obj.templateName.indexOf("docs") >= 0) {
								obj["path"] = "/documents";
							} else if (obj.templateName.indexOf("support") >= 0) {
								obj["path"] = "/support";
							}else if (obj.templateName.indexOf("praktikumBerufs")  >= 0) {
								obj["path"] = "/praktikumBerufs";
							} else if (obj.templateName.indexOf("myporsche")  >= 0) {
								obj["path"] = "/after-sales";//praktikum
							} else if (obj.templateName.indexOf("praktikum")  >= 0) {
								obj["path"] = "/praktikum";
							}else if (obj.templateName.indexOf("porsheBie")  >= 0) {
								obj["path"] = "/porsheBie";
							}

							if (index == 0) {
								firstMenuPage = obj;
							}
							menuData.push(obj);
						}
					});

					this.setState({
						firstMenuItemToDisplay: firstMenuPage,
						menuItems: menuData
					});
					sessionStorage.setItem('menuData',JSON.stringify(menuData));
					resolve("");
				});
		});
	};
	changeTeamHandler = () => {
		sessionStorage.clear("selectedTeam");
		
		this.setState({
			teamSelected: false,
			menuItems: []
		});
	};
	teamSelectHandler = () => {
		(async () => {
			this.getMenuIteamForSelctedTeam().then(() => {
				this.setState({
					teamSelected: true
				});
			});
		})();
	};
	componentDidMount() {
		this.state = {
			teamSelected: sessionStorage.getItem("selectedTeam") ? true : false
		};
		this.selectTeamFooter();
		this.allTeams();
	}

	selectTeamFooter() {
		client
			.getEntries({
				content_type: "onboardingTeams",
				"fields.name": sessionStorage.getItem("selectedTeam"),
				select: "fields.footer",
				include: 10
			})
			.then(entries => {
				if (entries) {
					this.setState({
						selectTeamFooter: entries.items[0]
					});
				}
			});
	}
	teamRender = () => {
		return (
			<SelectedTeam
				firstMenuItemToDisplay={this.state.firstMenuItemToDisplay}
				teamSelectHandler={this.teamSelectHandler}
				changeTeamHandler={this.changeTeamHandler}
				allTeams={this.state.allTeams}
				pageFooter={this.state.selectTeamFooter}
			/>
		);
	};

	loginRender = () => {
		if(this.props.location.pathname != "/" ){
				window.location.href = "/";
		}		
		return (
			<Login
				firstMenuItemToDisplay={this.state.firstMenuItemToDisplay}
				teamSelectHandler={this.teamSelectHandler}
				changeTeamHandler={this.changeTeamHandler}
				allTeams={this.state.allTeams}
				headerInSession={this.setHeaderInSession}
				pageFooter={this.state.selectTeamFooter}
			/>
		);
	}

	allTeams() {
		client
			.getEntries({
				content_type: "onboardingTeams",
				select: "fields.name",
				order: "fields.name"
			})
			.then(entries => {
				let groups = [];
				entries.items.forEach(entry => {
					if (entry.fields) {
						groups.push(entry.fields.name);
					}
				});
				this.setState({
					allTeams: groups
				});
			});
	}

	isMainPage (){		
		let path=window.location.href;
		let ar=path.split("/");
		return ar.length>3 && ar[3];
	}

	render() {
		return (
			<div>
				<Router>
					<div className="h-100">
						{this.state.teamSelected && this.isMainPage()? (
							<React.Fragment>
								<Header changeTeamHandler={this.changeTeamHandler} menuData={this.state.menuItems}  />
								<ContentNavigation changeTeamHandler={this.changeTeamHandler} menuData={this.state.menuItems} />
								<Switch>
									<Route path={"/"} exact render={this.loginRender} />
									<Route path={"/welcome/:id"} exact render={props=>(<WelcomeMenu {...props} menuData={this.state.menuItems} />)} />
									<Route path="/principle/:id" component={PorschePrincipleMenu} />
									<Route path="/after-sales/:id" component={MyPorscheMenu} />
									<Route path="/product/:id" component={PorshceProduct} />
									<Route path="/howweworkmenu/:id" component={HowWeWrokMenu} />
									<Route path="/praktikum/:id" component={PraktikumAndAbschlussarbeit} />
									<Route path="/praktikumBerufs/:id" component={BerufsAndHochschulen} />
									<Route path="/documents/:id" component={YourDocumentsMenu} />
									<Route path="/support/:id" component={SupportMenu} />
									<Route path="/porsheBie/:id" component={PorsheBieMenu} />
									<Route path="/map/" component={Map} />
								</Switch>
							</React.Fragment>
						) : (
							<React.Fragment>
								<Header menuData="" />
								<Route path="/" render={this.loginRender} firstMenuItemToDisplay={this.state.firstMenuItemToDisplay} />
							</React.Fragment>
						)}
					</div>
				</Router>
			</div>
		);
	}
}

export default withRouter(App);
