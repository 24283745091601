/** @format */

import React, { Component, Fragment } from "react";
import { ImageTag } from "../../dom/ImageTag";
export default class OnBoardingPerson extends React.Component {
	constructor(props) {
		super(props);
	}

	entryBlock(pageContent) {
		return (
			<div className="col-sm-4 PorscheTeams">
				<div className="your-team-sec ">
					<ImageTag
						className="img-fluid your-team-img"
						src={
							pageContent.data.target.fields &&
							pageContent.data.target.fields.image &&
							pageContent.data.target.fields.image.fields.file &&
							pageContent.data.target.fields.image.fields.file.url
						}
						isLazyLoad={true}
					/>
					<br></br>
					<h4 className="heading-4">{pageContent.data.target.fields && pageContent.data.target.fields.name}</h4>
					<p>{pageContent.data.target.fields && pageContent.data.target.fields.email}</p>
					<p>{pageContent.data.target.fields && pageContent.data.target.fields.jobPosition}</p>
					<span
						className={"social-btn" + (pageContent.data.target.fields && pageContent.data.target.fields.email ? "" : " pointer-disabled")}
					>
						<a href={`mailto:${pageContent.data.target.fields && pageContent.data.target.fields.email}`}>
							<i className="icon-mail mail-icon social-icon" aria-hidden="true"></i>
						</a>
					</span>

					<span className="social-btn">
						<a href={pageContent.data.target.fields && pageContent.data.target.fields.linkedIn}>
							<i className="icon-linked-in linkedin-icon social-icon" aria-hidden="true"></i>
						</a>
					</span>
				</div>
			</div>
		);
	}

	withoutEntryBlock(item) {
		if(item.data.target.fields.email === "berufsausbildung@porsche.de"){
		return (
			<div className="media">
				<div className="media-body team-member-dtl">
					<span className="team-name-title">{item.data.target.fields.name}</span>
					<label className="team-sub-title">{item.data.target.fields.jobPosition}</label>
					<br></br>
					<span className="team-name-title linkemail" ><a href={`mailto:${item.data.target.fields.email}`} style={{color:"#000000",textDecoration:"underline"}}>{item.data.target.fields.email}</a></span>
				</div>
			</div>
		);}else{
			return (
				<div className="media">
					<img
						src="..."
						alt="team-member"
						className="team-img"
						src={item.data.target.fields.image.fields.file && item.data.target.fields.image.fields.file.url}
					/>
					<div className="media-body team-member-dtl">
						<span className="team-name-title">{item.data.target.fields.name}</span>
						<label className="team-sub-title">{item.data.target.fields.jobPosition}</label>
						<br></br>
						<span className="team-name-title linkemail" ><a href={`mailto:${item.data.target.fields.email}`} style={{color:"#000000",textDecoration:"underline"}}>{item.data.target.fields.email}</a></span>
					</div>
				</div>
			);
		}
	}

	render() {
		let pageContent = this.props.pageContent;
		if (!pageContent) return <div></div>;

		if (pageContent) {
			if (this.props.isEntryBlock) {
				return <div className="row PorscheTeamsGrp">{this.entryBlock(pageContent)}</div>;
			} else {
				return (
					<div className="PorscheTeam feedbackBackground">
						{this.props.pageContent.content.map((item, key) => {
							if (item.nodeType === "embedded-entry-inline" && item.data.target.sys.contentType.sys.id == "onboardingPerson") {
								return this.withoutEntryBlock(item);
							}
						})}
					</div>
				);
			}
		}
	}
}
