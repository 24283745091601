import React, { Component, Fragment } from "react";
import { ImageTag } from "../dom/ImageTag";
export default class ImageBlock extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<React.Fragment>
				{this.props.imageArray.map((item, key) => {
					return (
						<div className="col-sm-4 col-6">
							<div className="accordion-card">
								
								<ImageTag className="img-fluid" src={item.fields && item.fields.file && item.fields.file.url} isLazyLoad={false}/>

								<a href={item.fields.description} className="read-more team-know-more">
									{item.fields.title}
								</a>
							</div>
						</div>
					);
				})}
			</React.Fragment>
		);
	}
}
