import React, { Component, Fragment } from 'react';



export default class OnboardingVideoLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            src: '',
            open: false,
            id:Math.floor((Math.random() * 10) ),
        }
    }
    closeVideo = () => {
        this.setState({
            src: ''
        })
    }
    playVideo = () => {
        let link = "";
        if (this.props.node.data.target.fields && this.props.node.data.target.fields.link) {
            link = this.props.node.data.target.fields.link;
            if (this.props.node.data.target.fields.link.indexOf("vimeo.com")>0) {
                let index = link.lastIndexOf("/")+1;
                link = "https://player.vimeo.com/video/" + link.substr(index);
            }
        }
        this.setState({
            src: this.props.node.data.target.fields && link + "?autoplay=1"
        })
    }
    render() {
        let videoButton;
        let videoLinkText;
        let videoModal
        if (this.props.node.data.target.fields && this.props.node.data.target.fields.link) {
            videoButton = <span className="video-icon" data-toggle="modal" onClick={this.playVideo} data-target={`#videoModal${this.state.id}`}><i className="icon-play"></i></span>

            videoModal = (
                <div className="modal fade" id={`videoModal${this.state.id}`} tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="videoModal" aria-hidden="true">
                    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content video-modal" >
                            <div className="modal-body" >
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={this.closeVideo}>&times;</button>
                                <div>
                                    <iframe id="iframeVideo" width="100%" height="350" src={this.state.src} allowFullScreen allow="autoplay"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        if (this.props.node.data.target.fields && this.props.node.data.target.fields.linkText) {
            videoLinkText = <label className="more-dtl" data-toggle="modal" onClick={this.playVideo} data-target={`#videoModal${this.state.id}`} style={{ paddingLeft: '10px' }}>{this.props.node.data.target.fields && this.props.node.data.target.fields.linkText}</label>
        }
        if (this.props.node.data.target.fields) {
            
            return (
    
                    <div className=' '>
                        {videoButton}
                        {videoLinkText}
                        {videoModal}
                    </div>

            )
        }
    }
}