/** @format */

import React, { Component, useEffect, useState } from "react";
import "../../css/MainContent.scss";
import "../../css/MainContent-mediaQuerry.scss";
import { Alert, Button, Dropdown } from "react-bootstrap";
import { appConstant } from "../../constant/appConstant";
import { withRouter } from "react-router-dom";
import Footer from "../footerHeader/Footer";
import "./login.scss";
import passSHA256 from "../../configuration/Security";
import client from "../../configuration/Client";

function Login(props) {
    const [state, setState] = useState({});
    const [teams,setTeams] = useState([]);

    useEffect(() => {
        sessionStorage.clear("selectedTeam");
        setState({
            pageFooter: {},
            password: "",
            error: false,
            erMsg:'',
            credential: [],
        });

        fetch("/credential.json")
            .then((r) => r.json())
            .then((data) => {
                setState({  ...state,credential: data });
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });


            
        client
        .getEntries({
            content_type: "onboardingTeams",
            select: "fields.name,fields.menuItems",
            include: 10
        })
        .then(entries => {
            setTeams(  entries.items ); 
        }); 
            
    }, []);

    const getUser = (password) => {
        let username = "";
        if(state.credential)
            state.credential.map((cred, index) => {
                if (cred.password === passSHA256(password))
                username = cred.name;
            });

        return username;
    };
	
    const selectTeamHandler = () => {
        const user = getUser(state.password);

        if (props.allTeams.includes(user)) {         
            if(teams && teams.filter(e=> e.fields.name === user)){
                let menus = teams.filter(e=> e.fields.name === user)[0].fields.menuItems;
                if(menus){
                    let id = menus[0].sys.id;
                    let name =  menus[0].fields.templateName;
                    sessionStorage.setItem("selectedTeam", user);

                    menus.map((item,k)=>{
                        setHeaderInSession(item.sys.id);
                    });

                    props.history.push(name+"/"+id);
                    props.teamSelectHandler();
                }else{
                    setState({ ...state, error: true,erMsg:'Content Not Found...' });
                }
            }
        } else {
            setState({ ...state, error: true ,erMsg:'Wrong Password...'});
        }
        return false;
    };

    const setHeaderInSession=(id)=>{ 
        if(id){ 
            props.headerInSession(id);
        }
    }
    const inputHandler = (e) => {
        setState({ ...state, password: e.target.value });
    };

    const keyPress = (e) => {
        if (e.keyCode == 13) {
            selectTeamHandler();
        }
    };
    const errorContent = state.error ? <Alert variant="danger">{state.erMsg}</Alert> : "";

    return (
        <main className="content-area main-content-area">
            <main>
                <div className="content-detail">
                    <div className="welcome-content-detail">
                        <div className="pageContents">
                            <div className="section-container">
                                {/* <div style={{ height: "auto", marginTop: "500px !important" }}> */}
                                <div style={{ textAlign: "center", color: "black"}}>
                                    <br />
                                    <h3>Willkommen bei Porsche</h3>
                                    <br />
                                    <input
                                        className="input-full"
                                        type="password"
                                        name="Password"
                                        placeholder="Password"
                                        onChange={(e) => inputHandler(e)}
                                        onKeyDown={(e) => keyPress(e)}
                                    />

                                    <br />
                                    {errorContent}
                                    <br />
                                    <div>
                                        <button
                                            class="anchor-button-red login-button anchor-button"
                                            size="lg"
                                            block
                                            onClick={(e) => selectTeamHandler()}
                                        >
                                            <i class="icon-arrow-right"></i>Anmelden
                                        </button>
                                        <br />
                                    </div>
                                    <br />
                                    <div className="login-reset-text">
                                    <span>Probleme bei der Anmeldung?</span>
                                    <br></br>
                                    <span>
                                        Kontaktiere: <a href="mailto:recruiting@porsche.de">recruiting@porsche.de</a>{" "}
                                    </span>
                                    </div>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer teamSelectionPage={true} pageFooter={props.pageFooter} />
        </main>
    );
}

export default withRouter(Login);
