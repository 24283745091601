import React, { Component, Fragment } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../../css/accordion.scss";
import ImageBlock from "./ImageBlock";
import $ from "jquery";
import OnBoardingHeadingInfo from "../contentType/onboardingHeadingInfo/OnBoardingHeadingInfo";
import { ImageTag } from "../dom/ImageTag";
import Heading2 from "../dom/Heading2";

export default class Accordion extends React.Component {
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		$(".panel-title a").unbind("click");
		$("#accordion").on("click", ".panel-title a", function() {
			setTimeout(() => {
				let isOpened = $(this)
					.parent()
					.prev()
					.hasClass("show");
				if (!isOpened) {
					$(this).addClass("my-according-color");
				} else {
					$(this).removeClass("my-according-color");
				}

				$(this)
					.parent()
					.prev()
					.collapse("toggle");
			}, 300);
		});
	}

	render() {
		let arrayest = new Array();
		let headingInfoData = "";

		for (var item in this.props.pageContent) {
			if (item == "content") {
				arrayest = this.props.pageContent[item];
			}
		}
		let renderedData = [];
		let index = 0;

		for (let i = 0; i <= arrayest.length; i++) {
			if (arrayest[i]) {
				if (arrayest[i].nodeType === "heading-2") {
					renderedData.push(
						<div className="card-1 padding-100" style={{ height: "auto", marginTop: "115px" }}>
							<Heading2 node={arrayest[i]}></Heading2>
						</div>
					);
				} else if (arrayest[i].nodeType === "paragraph") {
					renderedData.push(
						<div class="card-1 padding-100" style={{ height: "auto" }}>
							<p class="praghaph-3" style={{ textAlign: "left" }}>
								{arrayest[i].content[0].value}
							</p>
						</div>
					);
				} else if (arrayest[i].nodeType === "embedded-asset-block") {
					renderedData.push(
						<div className="principle-imc-sec padding-100">
							<ImageTag className="img-fluid" src={arrayest[i].data.target.fields.file.url} isLazyLoad={true} />
						</div>
					);
				} else if (arrayest[i].nodeType === "embedded-entry-block") {
					let ui = "";
					index++;
					let node = arrayest[i];
					if (node.data.target.sys.contentType.sys.id == "onboardingHeadingInfo") {
						headingInfoData = <OnBoardingHeadingInfo style={{ padding: "0px 0px" }} node={node} />;
					} else if (node.data.target.sys.contentType.sys.id == "onboardingAccordion") {
						const { fields } = node.data.target;
						let accInsiderData = [];
						let description = "";
						let descriptionArray = [];
						node.data.target &&
							node.data.target.fields &&
							node.data.target.fields.chapter &&
							node.data.target.fields.chapter.map((val, key) => {
								accInsiderData.push(<ImageBlock key={key} imageArray={val.fields.images} />);
							});

						if (fields.description) {
							const { content } = fields.description;
							content.map((val, key) => {
								if (val.nodeType == "unordered-list") {
									let unorderedList = val.content;
									if (unorderedList) {
										let bulletPoints = false;
										let bulletArray = [];
										for (let k = 0; k < unorderedList.length; k++) {
											if (unorderedList[k]) {
												if (unorderedList[k].content[0].content.length == 1) {
													descriptionArray.push(<li className="list-area dash-line">{unorderedList[k].content[0].content[0].value}</li>);
												} else if (unorderedList[k].content[0].content.length > 1) {
													bulletPoints = true;
													bulletArray.push(
														<div className="col-sm-6 porscheMeeting">
															<li className="list-area">
																<span className="label-bold">{unorderedList[k].content[0].content[0].value}</span>
																{unorderedList[k].content[0].content[1].value}
															</li>
														</div>
													);
												}
											}
										}
										if (bulletPoints) {
											descriptionArray.push(<div className="row porscheMeetingGrps">{bulletArray}</div>);
										}
									}
								} else {
									descriptionArray.push(val.content[0].value);
								}
							});
						}
						renderedData.push(
							<div class="panel panel-default">
								<div class="panel-heading" role="tab" id="headingOne">
									<h4 class="panel-title heading-3">{fields.name}</h4>

									{accInsiderData && accInsiderData.length > 0 ? (
										<div id={"collapseThree" + i} class="collapse" role="tabpanel" aria-labelledby="headingOne">
											<div class="panel-body">
												<div className="row">{accInsiderData}</div>
											</div>
										</div>
									) : (
										""
									)}

									{descriptionArray && descriptionArray.length > 0 ? (
										<div id={"collapseThree" + i} class="collapse" role="tabpanel" aria-labelledby="headingOne">
											<div class="panel-body">{descriptionArray}</div>
										</div>
									) : (
										""
									)}
									<h4 class="panel-title">
										<a
											class="collapsed"
											data-toggle="collapse"
											data-parent="#accordion"
											href="#"
											aria-expanded="false"
											aria-controls={"#collapseThree" + i}
										></a>
									</h4>
								</div>
							</div>
						);
					}
				}
			}
		}

		return (
			<div class="panel-group accordion-group" id="accordion" role="tablist" aria-multiselectable="true">
				{renderedData.map((data, key) => {
					return data;
				})}
				{headingInfoData}
			</div>
		);
	}
}
