/** @format */

import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import personImg from "../../images/person.png";
import $ from "jquery";
import { ImageTag } from "../dom/ImageTag";
export default class ContentFooterImg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }
    componentDidMount() {
        setTimeout(() => {
            $(".footer-sec .anchor-button-red").removeAttr("href");
            $(".footer-sec .anchor-button-red")
                .last()
                .on("click", () => {
                    $(".header-right .icon-arrow-right").trigger("click");
                });
        }, 1000);
    }

    render() {
        let navigatePath;
        const menuData = JSON.parse(sessionStorage.getItem("menuData"));

        for (let i = 0; i < menuData.length; i++) {
            if (this.props.pathName == `${menuData[i].path}/${menuData[i].id}`) {
                if (menuData[i + 1] && menuData[i + 1].path)
                    navigatePath = `${menuData[i + 1].path}/${menuData[i + 1].id}`;
            }
        }

        if (this.props.pathName == "/") {
            navigatePath = "/principal";
        } else if (this.props.pathName == "/principal") {
            navigatePath = "/myporsche";
        } else if (this.props.pathName == "/myporsche") {
            navigatePath = "/product";
        } else if (this.props.pathName == "/product") {
            navigatePath = "/howwewrokmenu";
        } else if (this.props.pathName == "/howwewrokmenu") {
            navigatePath = "/yourdocumentsmenu";
        } else if (this.props.pathName == "/yourdocumentsmenu") {
            navigatePath = "/supportmenu";
        }

        // console.log(`${navigatePath} - path **********`)

        if (
            this.props.pageFooter.fields &&
            this.props.pageFooter.fields.nextStep &&
            this.props.pageFooter.fields.nextStep.fields
        ) {
            return (
                <a href={navigatePath ? navigatePath : "#"} className="">
                    <div

                        className="support-bg-img footer-area mar-tp-100 d-flex flex-column justify-content-center"
                        style={{ backgroundImage: `url(${this.props.pageFooter.fields.nextStep})` }}
                    >

                        <ImageTag
                            className="img-fluid"
                            src={this.props.pageFooter.fields.nextStep.fields.image.fields.file.url + "?fm=jpg"}
                            isLazyLoad={true}
                        />

                        <div className="Footerbackground"></div>
                        <div className="section-container hrfootersec">
                            <div className="top-footer">
                                <h5 className="heading-4">
                                    {this.props.pageFooter.fields && this.props.pageFooter.fields.nextStep.fields.subline}
                                </h5>
                            </div>
                            <div className="footer-link">

                                <a style={{ color: "inherit", textDecoration: 'none' }} href={navigatePath ? navigatePath : "#"} className="">
                                    <h2 className="heading-2">
                                        {this.props.pageFooter.fields &&
                                            this.props.pageFooter.fields.nextStep.fields.headline}
                                    </h2>
                                </a>


                                <a style={{ color: "inherit" }} href={navigatePath ? navigatePath : "#"} className="">

                                    <i className="icon-arrow-right"></i>
                                </a>

                            </div>
                        </div>

                    </div>
                </a>
            );
        } else {
            return "";
        }
    }
}
