import React, { Component, Fragment } from "react";
export default class Heading6 extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { node } = this.props;
		return (
			<React.Fragment>
				<h6 className={this.props.classes}>{node.content && node.content[0].value}</h6>
			</React.Fragment>
		);
	}
}
