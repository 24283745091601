import React, { Component } from "react";
import { Route, Link, BrowserRouter as Router } from "react-router-dom";
import "../../css/footer.scss";
export default class Footer extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		let nodesLeft = [];
		let nodesRight = [];
		if (this.props.pageFooter.fields) {
			this.props.pageFooter.fields.footer.fields.footerInfo &&
				this.props.pageFooter.fields.footer.fields.footerInfo.content[0].content.map((node, key) => {
					if (node && node.nodeType === "embedded-entry-inline" && node.data.target.fields) {
						if (node.data.target.fields.align && node.data.target.fields.align === "left") nodesLeft.push(node.data.target.fields);
						else if (node.data.target.fields.align && node.data.target.fields.align === "right") nodesRight.push(node.data.target.fields);
					}
				});
		}
		return (
			<footer
				className={
					this.props.teamSelectionPage
						? "section-container main-footer footer d-flex align-items-center"
						: "section-container footer d-flex align-items-center"
				}
			>
				<div className="welcomeFooter">
					{/* {this.props.pageFooter.fields && this.props.pageFooter.fields.legalEntity} */}
					{!this.props.teamSelectionPage ? (
						<div className="legal-links">
							<Router>
								{nodesLeft &&
									nodesLeft.map((node, key) => {
										if (node.image)
											return (
												<a href={node.link}>
													<img src={node.image.fields.file.url} />{" "}
												</a>
											);
										else return <a  href={node.link}>{node.linkText}  </a>;
									})}
							</Router>
						</div>
					) : (
						""
					)}
					<div className="legal-links-right">
						<Router>
							{nodesRight &&
								nodesRight.map((node, key) => {
									if (node.image)
										return (
											<a target="_blank" href={node.link}>
												<img src={node.image.fields.file.url} />{" "}
											</a>
										);
									else
										return (
											<a className="text-legal-right" target="_blank" href={node.link}>
												{node.linkText}{" "}
											</a>
										);
								})}
						</Router>
					</div>
				</div>
			</footer>
		);
	}
}
