import React, { Component, Fragment } from "react";
import "../../css/MainContent.scss";
import "../../css/MainContent-mediaQuerry.scss";
import Footer from "../footerHeader/Footer";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ContentHeaderImg from "../common/ContentHeaderImg";
import ContentFooterImg from "../common/ContentFooterImg";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import client from "../../configuration/Client";
import calendaricon from "../../images/registration.png";
import OnBoardingPerson from "../contentType/onboardingperson/OnBoardingPerson";
import $ from "jquery";
import Heading3 from "../dom/Heading3";
import OnboardingNumberHeading from "../contentType/onboardingNumberHeading/OnboardingNumberHeading";
import Heading2 from "../dom/Heading2";
import OnBoardingImageBlock from "../contentType/onboardingImageBlock/OnBoardingImageBlock";
import { ImageTag } from "../dom/ImageTag";
import { commonRenderNode } from "../../constant/appConstant";
import ImgeBarBlock from "../contentType/onboardingImageBar/ImgeBarBlock";

export default class WelcomeMenu extends Component {
	constructor(props) {
		super(props);
		const {
			match: { params }
		} = this.props;
		this.state = {
			open: false,
			menuItem: "",
			pageContent: "",
			pageFooter: {},
			pageHeader: JSON.parse(sessionStorage.getItem(params["id"])),
			id: params["id"]
		};
	}

	componentDidUpdate() {}
	componentDidMount() {
		let options = {
			renderNode: {
				...commonRenderNode,
				["heading-2"]: node => {
					return (
						<div className="justSay">
							<Heading2 node={node} />
						</div>
					);
				},
				["paragraph"]: node => {
					if (node.content && node.content.length == 1) {
						return (
							<div className="card-1" style={{ height: "auto" }}>
								<p class="praghaph-3" style={{ textAlign: "left" }}>
									{node.content && node.content[0].value}
								</p>
							</div>
						);
					} else if (node.content && node.content.length > 1) {
						if (
							node.content[1].nodeType === "embedded-entry-inline" &&
							node.content[1].data.target.sys.contentType.sys.id === "onboardingTeaser"
						) {
							return (
								<div className="section-container card-section-container">
									<ImgeBarBlock pageContent={node} />
								</div>
							);
						}else if (
							node.content[1].nodeType === "embedded-entry-inline" &&
							node.content[1].data.target.sys.contentType.sys.id === "onboardingNumberHeadingText"
						) {
							return (
								<div className="paraNames card-1">
									<OnboardingNumberHeading node={node.content} />
								</div>
							);
						} else {
							return "";
						}
					}
				}
			}
		};

		client
			.getEntries({
				content_type: "onboardingPage",
				"sys.id": this.state.id,
				include: 10
			})
			.then(entry => {
				const { fields } = entry.items[0];
				if (fields) {
					this.setState({
						menuItem: fields.menuItem,
						//pageHeader: fields.pageHeader,
						pageFooter: fields.pageFooter,
						pageContent: documentToReactComponents(fields.pageContent, options)
					});
				}
			});
		window.scrollTo(0, 0);
	}
	onOpenModal = () => {
		this.setState(prevState => ({
			open: !prevState.open
		}));
	};

	moveToDocs = link => {
		//TODO
		link = "docs";
		const menuData = JSON.parse(sessionStorage.getItem("menuData"));
		let doc;
		for (let i = 0; i < menuData.length; i++) {
			if (menuData[i].templateName.indexOf(link) >= 0) {
				doc = menuData[i];
				break;
			}
		}

		return doc.path + "/" + doc.id;
	};
	render() {
		return (
			<div>
			<main className="content-area">
				<main>
					<ContentHeaderImg pageHeader={this.state.pageHeader} />
					<div className="content-detail">
						<div className="welcome-content-detail wilkomen-page">
							<div className="pageContents">{this.state.pageContent}</div>
						</div>
					</div>
					<ContentFooterImg pageFooter={this.state.pageFooter} pathName={this.props.history.location.pathname} />
				</main>
			</main>
			{ this.state.pageContent ? (<Footer pageFooter={this.state.pageFooter} />):('')}
				
			</div>
		);
	}
}
