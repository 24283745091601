const sha256 = require('js-sha256').sha256;



const passSHA256 = (pass) => {
    return sha256.hmac.create('key').update(pass).hex();
}
// Use this file to generate has of a password
// step 1. uncomment line 10 , Step 2. put your pass,Step 3 comment line 11, Step 4. open terminal to this file location, run command 'node Security'
//console.log(passSHA256('yourPass'))
export default passSHA256;