/** @format */

import React from "react";
import { ImageTag } from "../../dom/ImageTag";
import './imagebarblock.scss';

function ImgeBarBlock(props) {
	return (
		<div className="row margin0">
			{props.pageContent.content.map((item, key) => {
				if (item.nodeType === "embedded-entry-inline" && item.data.target.sys.contentType.sys.id == "onboardingTeaser") {
					const fields = item.data.target.fields;

					return (
						<div className="col-sm-6 padding-left5 col-12 bar11">
							<a style={{ color: "inherit" }} href={fields.link ? fields.link : "#"} className="">
								<div className="row card-image-box">
									<div className="card-image">
										<ImageTag className="img-fluid " src={fields.image && fields.image.fields.file.url} isLazyLoad={false} />
									</div>
									<div className="support-content-area">
										<a style={{ color: "inherit" }} href="#" className="">
											<i class="icon-arrow-right"></i>
											<span>{fields.linkText}</span>
										</a>
									</div>
								</div>
							</a>
						</div>
					);
				}
			})}
		</div>
	);
}

export default ImgeBarBlock;
