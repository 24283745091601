import React, { Component, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import $ from "jquery";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import iconExpand from "../../images/Icon-expand.svg";

export default class SwipePorsche extends React.Component {
	constructor(props) {
		super(props);
		let seipeData = [];
		this.props.data.map(function(value, key) {
			if (value.nodeType === "embedded-entry-inline") {
				seipeData.push(value.data.target);
			}
		});
		this.state = {
			data: seipeData,
			modelShow: false
		
		};
	}
	render() {
		let isDesktopDevice;

		if ($(window).width() < 990) {
			isDesktopDevice=true;
		}
		 else {
			isDesktopDevice=false;
		 }
		
		let imageExpand = iconExpand => {
			this.setState({
				modelShow: true,
				ExpandImage: iconExpand
			});
		};
		let close = () => {
			this.setState({
				modelShow: false
			});
		};
		return (
		


			<React.Fragment>
				<Carousel
					className="swipe-product"
					centerMode
					centerSlidePercentage={isDesktopDevice ? 100: 80}
					width={800}
					autoPlay={false}
					emulateTouch={true}
					showArrows={false}
					showThumbs={false}
					showIndicators={true}
				>
					{this.state.data.map(function(item, key) {
						console.log(item);
						return (
							<React.Fragment>
								<div className="swipe-section">
									{item.fields.link ?
									 (
									 <div className="text-section content-box">
										 <a href={item.fields.link } className="" style={{textDecoration: 'none' }}>
									 	{/* <p className="name praghaph-3">{item.fields.name}</p> */}
									 	<p className="heading heading-3" >{item.fields.heading}</p>
										 </a>
										 <p className="description praghaph-3">{item.fields.description}</p>										
										
									 </div> )
									 :
									(<div className="text-section content-box">
										{/* <p className="name praghaph-3">{item.fields.name}</p> */}
										<p className="heading heading-3">{item.fields.heading}</p>
										<p className="description praghaph-3">{item.fields.description}</p>										
									</div>)
									}									
									<div
										className={
											item.fields.image && item.fields.image.fields && item.fields.image.fields.file && item.fields.image.fields.file.url
												? "image-section content-box image-attached"
												: "image-section content-box"
										}
									>
										{item.fields.image && item.fields.image.fields && item.fields.image.fields.file && item.fields.image.fields.file.url ? (
											<div className="img-content">
												<img src={item.fields.image.fields.file.url} />
											</div>
										) : (
											" "
										)}
									</div>
								</div>
							</React.Fragment>
						);
					})}
				</Carousel>
			</React.Fragment>
		);
	}
}
